import React from "react";

const PersonalInfo = () => {
  return (
    <>
      <div className="col-lg-6 single-info-wrapper heb_font">
        <div className="single-info">
          <div className="single-info-text">
            <span>
              <h4 style={{ fontFamily: "Rubik", direction: "rtl" }}>כתובתנו</h4>
            </span>
            <h5 style={{ fontFamily: "Rubik" }}>פינלס 4, תל אביב</h5>
            <h5 style={{ fontFamily: "Rubik" }}>ישראל</h5>
          </div>
        </div>
      </div>
      <div className="col-lg-6 single-info-wrapper heb_font">
        <div className="single-info">
          <div className="single-info-text">
            <span>
              {" "}
              <h4 style={{ fontFamily: "Rubik" }}>אודותינו</h4>
            </span>
            <p style={{ fontFamily: "Rubik" }}>
              מאז 2018, אנו מקדישים תשומת לב מיוחדת לבניית אתרים שעונים על
              ציפיות הלקוחות בכל שלב של התהליך. נמשיך לשמור על רמת שירות גבוהה
              ואישית, מתחילת התהליך ועד סיומו. אנו מאמינים כי ההצלחה של
              לקוחותינו היא גם ההצלחה שלנו, ולכן אנו מקנים להם ייעוץ אישי וליווי
              מקצועי לאורך כל דרכם. בנוסף, אנו מחויבים לספק פתרונות מותאמים
              אישית המשקפים את צרכי העסק ומסייעים בהגשמת המטרות המקצועיות שלו.
            </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 single-info-wrapper heb_font">
        <div className="single-info">
          <div className="single-info-text">
            <span>
              <h4 style={{ fontFamily: "Rubik" }}>שעות פתיחה</h4>
            </span>
            <p style={{ fontFamily: "Rubik" }}>
              יום ראשון - חמישי: 9:00 עד 22:00
            </p>
            <p style={{ fontFamily: "Rubik" }}>יום שישי: 10:00 עד 15:00</p>
            <p style={{ fontFamily: "Rubik" }}>
              נשמח לראותכם בעסק שלנו במהלך השעות המפורטות לעיל.
            </p>
            <p style={{ fontFamily: "Rubik" }}>או במענה הטלפוני שלנו </p>
          </div>
        </div>
      </div>
      <div className="col-lg-6 single-info-wrapper heb_font">
        <div className="single-info">
          <div className="single-info-text">
            <span>
              <h4 style={{ fontFamily: "Rubik" }}>חפשו אותנו במדיה </h4>
            </span>
            <p>
              <a
                style={{ fontFamily: "Rubik" }}
                href="https://www.linkedin.com/in/eran-samimian-6b897a233/"
                target="_blank"
              >
                linkedin
              </a>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInfo;
