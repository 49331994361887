import React from "react";
import SlidesHeader from "./SlidesHeader";
import { HashLink as Link } from "react-router-hash-link";
import icon1 from "../assets/img/icon/1.png";
import icon2 from "../assets/img/icon/2.png";
import icon3 from "../assets/img/icon/3.png";

const Skills = () => {
  return (
    <>
      <div className="skill-area section-area bg-cover">
        <SlidesHeader />
        <div style={{ direction: "" }} className="container ">
          <div className="row align-items-center vt-mt-100">
            <div className="col-lg-1 col-12">
              <div className="skill-title">
                <h2
                  style={{ fontFamily: "Rubik" }}
                  className="rotate-title section-title"
                >
                  השירות שלי
                </h2>
              </div>
            </div>
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                <div className="col-lg-4 col-md-6 single-skill-wrap heb_font">
                  <div className="single-skill">
                    <div className="single-skill-icon">
                      <img src={icon1} alt="icon1" />
                    </div>
                    <div className="single-skill-title">
                      <h4>
                        <Link
                          style={{ fontFamily: "Rubik" }}
                          to="/service-single#"
                        >
                          עיצוב אתרים.
                        </Link>
                      </h4>
                    </div>
                    <div className="single-skill-text">
                      <p style={{ fontFamily: "Rubik" }}>
                        אתר מעוצב הוא מפתח להצלחה בעולם הדיגיטלי, מייצר חווית
                        משתמש מדהימה ומושכת לקוחות. בעזרת צוות מנצח של מעצבים,
                        אנו יוצרים אתרים ברמת גימור גבוהה ומעוררים עניין. אתר
                        מעוצב היטב מקל על הקידום ומגביר מכירות, וכאשר מציע חווית
                        גלישה נעימה ומרשימה, מעודד את הלקוחות לחזור שוב ושוב.
                        בחירה באתר מעוצב היטב היא הבחירה הנכונה להצלחה בעולם
                        הדיגיטלי.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 single-skill-wrap heb_font">
                  <div className="single-skill">
                    <div className="single-skill-icon">
                      <img src={icon2} alt="icon2" />
                    </div>
                    <div className="single-skill-title">
                      <h4>
                        <Link
                          style={{ fontFamily: "Rubik" }}
                          to="/service-single#"
                        >
                          בניית אתרים
                        </Link>
                      </h4>
                    </div>
                    <div className="single-skill-text">
                      <p style={{ fontFamily: "Rubik" }}>
                        מעל 80% מהמבקרים עוזבים את האתר תוך 15 שניות בלעדי רושם
                        ראשוני. התוכן, העיצוב וחוויית המשתמש משמעותיים מאוד. אני
                        מציע שירות מקצועי ואישי, כולל מיתוג, שיווק וקידום בגוגל.
                        אם רוצים אתר מקצועי ואיכותי, פשוט צרו איתי קשר.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 single-skill-wrap heb_font">
                  <div className="single-skill">
                    <div className="single-skill-icon">
                      <img src={icon3} alt="icon3" />
                    </div>
                    <div className="single-skill-title">
                      <h4>
                        <Link
                          style={{ fontFamily: "Rubik" }}
                          to="/service-single#"
                        >
                          קידום ושיווק
                        </Link>
                      </h4>
                    </div>
                    <div className="single-skill-text">
                      <p style={{ fontFamily: "Rubik" }}>
                        מתמחה באסטרטגיות דיגיטליות, כולל קידום אתרים בגוגל,
                        בניית אתרים, ועמודי נחיתה. מוחמה בעיצוב מודרני וניווט
                        קל. פועל בפרסום דיגיטלי, ניהול קמפיינים ברשתות חברתיות,
                        ו-SEO/SEM. מתמקד בתוכן איכותי ומשפיע. מציע פתרונות
                        מותאמים אישית.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Skills;
