import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="privacy-policy regulations_class">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>מדיניות פרטיות</h2>
              <p>
                ברוכים הבאים לשירות האתרים שלנו. באופן כללי, האתר מכבד ושומר על
                הפרטיות של המשתמשים שלו. המדיניות הזו מסבירה אילו סוגי מידע אישי
                אנו אוספים ומקבלים מהמשתמשים, וכיצד אנו משתמשים במידע הזה.
              </p>
              <h3>מידע שאנו אוספים</h3>
              <p>
                אנו מאגדים מידע כללי על המשתמשים שלנו, כולל את המידע הנדרש לצורך
                תהליכי הזמנה ושירות הלקוחות. זה כולל את השם, הכתובת האימייל
                והמספר טלפון.
              </p>
              <h3>שימוש במידע</h3>
              <p>
                המידע שאנו אוספים משמש לצורך הזמנות, עקבות ושירות לקוחות. אנו
                יכולים להשתמש במידע הזה גם לצורך פרסומות ושיווק בקרב הלקוחות
                שלנו.
              </p>
              <h3>שיתוף מידע</h3>
              <p>
                אנו לא משתפים, לא מוכרים ולא משקיעים מידע אישי של משתמשינו לצד
                שלישיים.
              </p>
              <h3>אבטחת מידע</h3>
              <p>
                אנו מחזיקים מידע אישי על משתמשינו באופן מאובטח ובמערכות מחשוב
                מוגנות.
              </p>
              <h3>קישורים לאתרים חיצוניים</h3>
              <p>
                אתרנו עשוי לכלול קישורים לאתרים חיצוניים של צדדים שלישיים. אנו
                לא אחראים על מדיניות הפרטיות או התוכן של אתרים אלו.
              </p>
              <h3>שינויים למדיניות פרטיות</h3>
              <p>
                נשמר לנו הזכות לעדכן או לשנות את מדיניות הפרטיות שלנו מתי שנראה
                לנו שנדרש לכך. כל שינויים יישארו בתוקף עם פרסום המדיניות
                המעודכנת באתר.
              </p>
              <h3>צור קשר</h3>
              <p>
                אם יש לך שאלות או בקשות לגבי מדיניות הפרטיות שלנו, אל תהסס ליצור
                איתנו קשר.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
