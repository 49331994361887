import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Mousewheel, Navigation, Pagination } from "swiper";
import "swiper/css";
import Banner from "../Components/Banner";
import Skills from "../Components/Skills";
import Portfolio from "../Components/Portfolio";
import Resume from "../Components/Resume";
import Blog from "../Components/Blog";
import Contact from "../Components/Contact";

const Slides = () => {
  const skillsRef = useRef(null);

  const scrollToSkills = () => {
    skillsRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Swiper
        speed={1000}
        direction={"horizontal"}
        mousewheel={true}
        modules={[Pagination, Mousewheel, Navigation]}
        loop={true}
        pagination={{
          type: "fraction",
        }}
        navigation={true}
      >
        <SwiperSlide>
          {" "}
          <Banner />{" "}
        </SwiperSlide>
        <SwiperSlide ref={skillsRef}>
          {" "}
          <Skills />{" "}
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Portfolio />{" "}
        </SwiperSlide>
        <SwiperSlide>
          {" "}
          <Resume />{" "}
        </SwiperSlide>
        {/* <SwiperSlide > <Blog /> </SwiperSlide> */}
        <SwiperSlide>
          {" "}
          <Contact />{" "}
        </SwiperSlide>
      </Swiper>
      {/* <button onClick={scrollToSkills}>Go to Skills</button> */}
    </>
  );
};

export default Slides;
