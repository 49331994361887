import React from "react";
import PrivacyPolicy from "../Components/privecy";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";

const PrivecySingle = () => {
  return (
    <>
      <Header />
      <Breadcrumbs pageTitle="מדיניות פרטיות" />
      <PrivacyPolicy />
      <Footer />
    </>
  );
};

export default PrivecySingle;
