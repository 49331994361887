import React, { useEffect } from "react";
import { toast } from "react-toastify";
import config from "../utils/Config";
const ContactForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    return () => {};
  }, []);

  const handleMessage = async (event) => {
    debugger;
    event.preventDefault();
    const formData = new FormData(event.target);
    try {
      await fetch(`${config.mainUrl}api/send-email`, {
        method: "POST",
        body: formData,
      });
      toast.success("ההודעה נשלחה בהצלחה, תודה");
      event.target.reset();
    } catch (error) {
      console.error("Error sending email:", error);
      toast.error("אירעה שגיאה בשליחת ההודעה");
    }
  };

  return (
    <>
      <form onSubmit={handleMessage}>
        <div className="row rtl_class">
          <div className="col-lg-12">
            <input type="text" name="name" placeholder="שם מלא*" />
          </div>
          <div className="col-lg-6">
            <input type="email" name="email" placeholder="מייל*" />
          </div>
          <div className="col-lg-6">
            <input type="number" name="number" placeholder="מס טלפון*" />
          </div>
          <div className="col-lg-12">
            <textarea name="project" placeholder="השאר הודעה*"></textarea>
          </div>
          <div className="col-lg-12">
            <button
              className="btn btn-md circle btn-theme effect sent-btn"
              type="submit"
            >
              שלח
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default ContactForm;
