class Config {}

class DevelopmentConfig extends Config {
  constructor() {
    super();
    this.mainUrl = "http://localhost:3001/";
  }
}

class ProductionConfig extends Config {
  constructor() {
    super();
    this.mainUrl = "https://backend.web2u.co.il/";
  }
}

const config =
  process.env.NODE_ENV === "Production"
    ? new DevelopmentConfig()
    : new ProductionConfig();

module.exports = config;
