import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info vt-md-mb-70">
        <ul>
          <li className="contact-info-list">
            <div className="contact-info-icon" style={{ marginRight: "20px" }}>
              <i className="ri-mail-line"></i>
            </div>
            <div className="contact-info-text">
              <h5>מייל</h5>
              <p>
                <a href="mailto:info@validtheme.com">eransam21@gmail.com</a>
              </p>
            </div>
          </li>
          <li className="contact-info-list">
            <div className="contact-info-icon" style={{ marginRight: "20px" }}>
              <i className="ri-map-pin-line"></i>
            </div>
            <div className="contact-info-text">
              <h5>טלפון</h5>
              <p>
                <a href="tel:0524258058">0524258058</a>
              </p>
            </div>
          </li>
          <li className="contact-info-list">
            <div className="contact-info-icon" style={{ marginRight: "20px" }}>
              <i className="ri-headphone-line"></i>
            </div>
            <div className="contact-info-text">
              <h5>שירות וואצאפ</h5>
              <p>
                <a href="tel:0524258058">whatsapp</a>
              </p>
              <p></p>
            </div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ContactInfo;
