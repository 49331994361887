import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import logo from "../assets/img/logo/logo.png";

const HeaderOffCanvasMenu = () => {
  return (
    <>
      <div className="header-offcanvas-area d-lg-none">
        <button
          className="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasRight"
          aria-controls="offcanvasRight"
        >
          <span className="header-hamburger">
            <i className="ti-menu"></i>
          </span>
        </button>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-header">
            <h5 className="mb-0" id="offcanvasRightLabel">
              <Link to="/#">
                {" "}
                <h2 style={{ marginTop: "15px" }}>Web2U</h2>
                {/* <img src={logo} alt="logo" /> */}
              </Link>
            </h5>
            <button
              type="button"
              className="close-btn text-reset"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <i className="ri-close-line"></i>
            </button>
          </div>

          <div className="offcanvas-body">
            <div className="offcanvas-body-wrap">
              <nav>
                <ul className="text_right">
                  {/* דף הבית */}
                  <li className="heb_font">
                    <Link to="/#">דף הבית</Link>
                  </li>

                  {/* אודותינו */}
                  <li className="heb_font">
                    <Link to="/resume-page#">אודותינו</Link>
                  </li>
                  {/* דפים */}
                  <li className="dropdowns heb_font">
                    <Link to="#">דפים</Link>
                    <ul className="submenu heb_font">
                      <li>
                        <Link className="heb_font" to="/WebEcommerce#">
                          תיק עבודות
                        </Link>
                      </li>
                      <li>
                        <Link className="heb_font" to="/RegulationsSingle#">
                          תקנון{" "}
                        </Link>
                      </li>

                      <li>
                        <Link className="heb_font" to="/PrivecySingle#">
                          מדיניות פרטיות{" "}
                        </Link>
                      </li>

                      {/* <li><Link to="/portfolio-single#">Portfolio Single</Link></li>
                        <li><Link to="/blog-single#">Blog Single</Link></li>
                        <li><Link to="/not-found#">Error Page</Link></li> */}
                    </ul>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderOffCanvasMenu;
