import React from "react";

const PreLoader = () => {
  return (
    <>
      <div id="preloader">
        <div id="ambrox-preloader" className="ambrox-preloader">
          <div className="animation-preloader">
            <div className="spinner"></div>
            <div className="txt-loading">
              <span data-text-preloader="W" className="letters-loading">
                W
              </span>
              <span data-text-preloader="E" className="letters-loading">
                E
              </span>
              <span data-text-preloader="B" className="letters-loading">
                B
              </span>
              <span data-text-preloader="2" className="letters-loading">
                2
              </span>
              <span data-text-preloader="U" className="letters-loading">
                U
              </span>
            </div>
          </div>
          <div className="loader">
            <div className="row">
              <div className="col-3 loader-section section-left">
                <div className="bg"></div>
              </div>
              <div className="col-3 loader-section section-left">
                <div className="bg"></div>
              </div>
              <div className="col-3 loader-section section-right">
                <div className="bg"></div>
              </div>
              <div className="col-3 loader-section section-right">
                <div className="bg"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreLoader;
