import React from "react";
import SlidesHeader from "./SlidesHeader";
import { HashLink as Link } from "react-router-hash-link";
import portfolio1 from "../assets/img/portfolio/git_photo.png";
import portfolio4 from "../assets/img/portfolio/LANDING.png";
import portfolio6 from "../assets/img/portfolio/google_biss_photo.png";

const Portfolio = () => {
  return (
    <>
      <div className="portfolio-area section-area bg-cover">
        <SlidesHeader />
        <div className="container ">
          <div className="row align-items-center vt-mt-100">
            <div className="col-lg-1">
              <div className="portfolio-title section-title">
                <h2 className="rotate-title heb_font">תיק עבודות</h2>
              </div>
            </div>
            <div className="col-lg-10 offset-lg-1">
              <div className="row">
                <div className="col-lg-6 col-xl-4 col-12 single-portfolio-wrapper">
                  <div className="single-portfolio">
                    <div className="single-portfolio-thumb">
                      <img
                        className="img_protfolio_size"
                        src={portfolio4}
                        alt="portfolio4"
                      />
                    </div>
                    <div className="single-portfolio-hover d-flex align-items-center">
                      <div className="portfolio-hover-text ">
                        <span>בנייה</span>
                        <Link to="/WebEcommerce#" className="d-block">
                          אתרי אינטרנט
                        </Link>
                      </div>
                      <div className="portfolio-hover-icon">
                        <Link to="/WebEcommerce#">
                          {" "}
                          <i className="ri-arrow-right-line"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 2 */}
                <div className="col-lg-6 col-xl-4 col-12 single-portfolio-wrapper">
                  <div className="single-portfolio">
                    <div className="single-portfolio-thumb">
                      <img
                        className="img_protfolio_size"
                        src={portfolio1}
                        alt="portfolio1"
                      />
                    </div>
                    <div className="single-portfolio-hover d-flex align-items-center">
                      <div className="portfolio-hover-text ">
                        <span>פרוייקטים</span>
                        <a
                          href="https://github.com/eransam"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-block"
                        >
                          פרוייקטים בגיט
                        </a>
                      </div>
                      <div className="portfolio-hover-icon">
                        <a
                          href="https://github.com/eransam"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-block"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 3 */}
                <div className="col-lg-6 col-xl-4 col-12 single-portfolio-wrapper">
                  <div className="single-portfolio">
                    <div className="single-portfolio-thumb">
                      <img
                        className="img_protfolio_size"
                        src={portfolio6}
                        alt="portfolio6"
                      />
                    </div>
                    <div className="single-portfolio-hover d-flex align-items-center">
                      <div className="portfolio-hover-text ">
                        <span>המלצות</span>
                        <a
                          href="https://maps.app.goo.gl/8wHw9SJdDAWK4TGu5"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-block"
                        >
                          המלצות
                        </a>
                      </div>
                      <div className="portfolio-hover-icon">
                        <a
                          href="https://maps.app.goo.gl/8wHw9SJdDAWK4TGu5"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-block"
                        >
                          <i className="ri-arrow-right-line"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Portfolio;
