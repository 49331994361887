import React from "react";

const WebsiteBuildingRegulations = () => {
  return (
    <>
      <div className="website-building-regulations regulations_class">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <h2>תקנון בסיסי לשירות בניית אתרים</h2>
              <p>
                תודה שבחרת בשירות הבניית אתרים שלנו. להלן כמה הנחיות ותקנון
                בסיסי לשימוש בשירות שלנו:
              </p>
              <ul>
                <li>
                  נשתדל לעבוד בקרוב איתך על מנת להבין את דרישותיך והעדפותיך
                  לעיצוב ולפונקציות של האתר.
                </li>
                <li>
                  במהלך הפיתוח, עשויים לבקש ממך משוב וקלט כדי לוודא שהאתר עומד
                  בציפיותיך.
                </li>
                <li>
                  ברגע שהאתר יהיה מוכן, נעניק לך אפשרות לסקור ולבדוק אותו לפני
                  שהוא יישמר.
                </li>
                <li>
                  תוספות נוספות או שינויים שיידרשו לאחר שהאתר יהיה מוכן עשויים
                  להוציא עלות נוספת.
                </li>
                <li>
                  נשתדל למסור את האתר במסגרת הזמן שהוסכם, אך יתכן דיחויים עקב
                  תנאים לא צפויים.
                </li>
                <li>
                  חשוב לספק תוכן ברור ומדוייק לאתר, כולל טקסט, תמונות ומדיה
                  נוספת.
                </li>
                <li>
                  נבצע ווידוא שהאתר נגיש ותואם לדפדפני הרשת המודרניים ולמכשירים
                  השונים.
                </li>
                <li>
                  ברגע שהאתר מוסר אליך, תהיה עליך האחריות לתחזק ולעדכן את התוכן
                  באופן קבוע.
                </li>
                <li>
                  נשמור על הזכות להציג את האתר המוכן בתיק העבודות שלנו ובחומרי
                  שיווק, אלא אם נסכם אחרת.
                </li>
              </ul>
              <p>
                בשימוש בשירות הבניית האתרים שלנו, אתה מסכים להיכנס לתוקף של
                התקנון הזה. אם יש לך שאלות או דברים לגבי זה, אנא אל תהסס ליצור
                איתנו קשר.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WebsiteBuildingRegulations;
