import React from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import PortfolioDetailsLandingPage from "../Components/PortfolioDetails_web_landing";
import Footer from "../Components/Footer";

const WebLanding = () => {
  return (
    <>
      <Header />
      <Breadcrumbs pageTitle="Landing Page" />
      <PortfolioDetailsLandingPage />
      <Footer />
    </>
  );
};

export default WebLanding;
