import React from "react";
import WebsiteBuildingRegulations from "../Components/regulations";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import Footer from "../Components/Footer";

const RegulationsSingle = () => {
  return (
    <>
      <Header />
      <Breadcrumbs pageTitle="תקנון" />
      <WebsiteBuildingRegulations />
      <Footer />
    </>
  );
};

export default RegulationsSingle;
