import React from "react";
import Header from "../Components/Header";
import Breadcrumbs from "../Components/Breadcrumbs";
import PortfolioDetailsSoftwere from "../Components/PortfolioDetails_softwere";

import Footer from "../Components/Footer";

const WebSoftwere = () => {
  return (
    <>
      <Header />
      <Breadcrumbs pageTitle="תוכנות ניהול" />
      <PortfolioDetailsSoftwere />
      <Footer />
    </>
  );
};

export default WebSoftwere;
