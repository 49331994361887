import React from "react";
import Typed from "react-typed";
import SlidesHeader from "./SlidesHeader";
import shape4 from "../assets/img/shape/4.png";
import shape7 from "../assets/img/shape/7.png";
import illustration3 from "../assets/img/illustration/eran_big.png";
import { Link } from "react-router-dom";

const Banner = () => {
  let textLines = [
    '<b className="is-visible">התאמה למובייל</b>',
    '<b className="is-visible">קוד מאובטח</b>',
    '<b className="is-visible">עיצוב אתרים</b>',
  ];

  return (
    <>
      <div className="banner-area section-area bg-cover">
        <SlidesHeader />
        <div className="container  ">
          <div className="row align-items-center vt-mt-100">
            <div className="col-lg-6">
              <div className="banner-left text-light">
                <div className="banner-text">
                  <div>
                    <h3>
                      {" "}
                      <span className="banner-title">שלום, שמי ערן</span>
                    </h3>
                    <img className="shape4" src={shape4} alt="shape4" />
                  </div>
                  <div>
                    <h4>
                      {" "}
                      <span
                        className="banner-title"
                        style={{ lineHeight: "1.5" }}
                      >
                        בניית אתרים, שדרוג והוספה לאתרים קיימים, פתרון תקלות
                        נקודתיות
                      </span>
                    </h4>
                  </div>
                  <div>
                    <h4>
                      {" "}
                      <span className="banner-title">החל מ ₪99</span>
                    </h4>
                  </div>
                  <div>
                    <h3>
                      <span>
                        <Typed
                          strings={textLines}
                          typeSpeed={35}
                          backSpeed={30}
                          loop
                        ></Typed>
                      </span>
                    </h3>{" "}
                  </div>

                  <br />
                  <button className="btn btn-md circle btn-theme effect">
                    <Link to="/contact-page" className="btn-link">
                      צרו קשר
                    </Link>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="banner-right vt-md-mb-60">
                <div className="banner-image img-fluid h-auto">
                  <img
                    // style={{ width: "390px" }}
                    src={illustration3}
                    alt="illustration3"
                  />
                </div>
                <div className="banner-shape img-fluid">
                  <img src={shape7} alt="shape7" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
