import React from "react";
import { useState } from "react";
import SocialShare from "./SocialShare";
import { toast } from "react-toastify";
import { NavLink } from "react-router-dom";
import logoIcon from "../assets/img/logo/logo_e.png";
import logoIcon_whatsapp from "../assets/img/logo/WhatsApp_logo.png";
import { HashLink as Link } from "react-router-hash-link";

import logoLight from "../assets/img/logo/logo-light.png";
const SlidesHeader = () => {
  const whatsappNumber = "+972524258058"; // Replace with your WhatsApp number
  const message = "Hello! I want to chat with you."; // Replace with your desired message

  // Function to open WhatsApp with pre-filled message
  const openWhatsApp = () => {
    window.open(
      `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(message)}`,
      "_blank"
    );
  };

  let [canvasToggle, setCanvasToggle] = useState(false);

  let handleSubsScribe = (event) => {
    event.preventDefault();
    event.target.reset();
    toast.success("Subscribed Successfully");
  };

  return (
    <>
      <div className="slides-header-area">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-4">
              <div className="logo-icon">
                <NavLink to="/#">
                  <img src={logoIcon} alt="logoIcon" />
                </NavLink>
              </div>
            </div>
            <div className="col-md-6 col-8">
              <div className="slides-header-right">
                <div
                  className="slides-menu"
                  onClick={() => setCanvasToggle(!canvasToggle)}
                >
                  <span className="bar-1"></span>
                  <span className="bar-2"></span>
                  <span className="bar-3"></span>
                </div>
                {/* <div className="chat-btn">
                  <NavLink to="#">
                    <i className="ri-chat-1-line"></i>
                    <span>Chat Now</span>
                  </NavLink>
                </div> */}
                <div className="chat-btn">
                  {/* Call the openWhatsApp function when the button is clicked */}
                  <button className="whatsapp_btn" onClick={openWhatsApp}>
                    {/* <i className="ri-chat-1-line"></i> */}
                    <span>
                      <img
                        className="WhatsApp_logo"
                        src={logoIcon_whatsapp}
                        alt="logoIcon"
                      />
                    </span>

                    {/* <span style={{ color: "#25d366" }}>WhatsApp</span> */}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* slides-offcanvas */}
      <div
        className={`${
          canvasToggle
            ? "header-right-canvas open"
            : "header-right-canvas close"
        }`}
      >
        <div className="canvas-wrapper">
          <div className="canvas-header">
            <NavLink to="/#">
              <h2 className="heb_font_white">web2u</h2>
              {/* <img src={logoLight} alt="logoLight" /> */}
            </NavLink>
            <button
              className="close-btn"
              onClick={() => setCanvasToggle(!canvasToggle)}
            >
              <i className="ri-close-line"></i>
            </button>
          </div>
          <div className="canvas-body">
            <div className="canvas-nav widget">
              <ul>
                <li>
                  <Link className="heb_font" to="/WebEcommerce#">
                    תיק עבודות
                  </Link>
                </li>
                <li>
                  <Link className="heb_font" to="/RegulationsSingle#">
                    תקנון{" "}
                  </Link>
                </li>

                <li>
                  <Link className="heb_font" to="/PrivecySingle#">
                    מדיניות פרטיות{" "}
                  </Link>
                </li>

                <li>
                  <NavLink to="//resume-pag#" className="heb_font">
                    אודותינו
                  </NavLink>
                </li>

                <li>
                  <a
                    href="https://maps.app.goo.gl/8wHw9SJdDAWK4TGu5"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="d-block heb_font"
                  >
                    המלצות{" "}
                  </a>
                </li>

                <li>
                  <NavLink to="/#" className="heb_font">
                    דף הבית
                  </NavLink>
                </li>
              </ul>
            </div>
            {/* <div className="canvas-about widget">
              <div className="single-about">
                <h6>כתובת</h6>
                <span>פינלס 4, תל אביב</span>
              </div>
              <div className="single-about">
                <h6>EMAIL</h6>
                <span>eransam21@gmail.com</span>
              </div>
              <div className="single-about">
                <h6>טלפון</h6>
                <span>0524258058</span>
              </div>
            </div>
            <div className="canvas-newsletter widget">
              <h4 className="title">Get Subscribed!</h4>
              <form onSubmit={handleSubsScribe}>
                <div className="canvas-search">
                  <input
                    type="email"
                    name="email"
                    title="Enter Your E-mail"
                    placeholder="Enter your e-mail"
                  />
                  <button type="submit">
                    <i className="ti-arrow-right"></i>
                  </button>
                </div>
              </form>
            </div> */}
            {/* <div className="canvas-social">
              <SocialShare />
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default SlidesHeader;
